<template>
  <div>
    <div class="fz-20 mb-1">
      Transaction Detailed
    </div>

    <v-progress-linear
      :active="isLoading"
      :indeterminate="isLoading"
      :height="1"
      class="pa-0 ma-0"
    />
    <div v-if="!isLoading && Object.keys(itemData).length > 0">
      <v-card class="main">
        <div class="info-line mb-2">
          <span class="label">Payment status:</span>
          <span
            class="bold"
            :class="statusColor(itemData.status)"
            v-text="itemData.status"
          ></span>
        </div>
        <div class="info-line mb-2">
          <span class="label">Created date:</span>
          <span v-text="dateFormat(itemData.date)"></span>
        </div>
        <div class="info-line mb-2">
          <span class="label">Number of payments:</span>
          <span v-text="itemData.payments"></span>
        </div>
        <div class="info-line mb-2">
          <span class="label">Payment method:</span>
          <span v-text="itemData.type"></span>
        </div>

        <TransactionInfo
          :statuses="itemData.statuses"
          :amount="itemData.amount"
          :fees="itemData.fees"
        />
      </v-card>

      <TransactionDetailedTable :transactions-id="itemData.id" />
    </div>
  </div>
</template>

<script>
import TransactionDetailedTable from "@/components/TransactionDetailed/TransactionDetailedTable"
import TransactionInfo from "@/components/TransactionDetailed/TransactionInfo"
import { fetchData } from "@/utils/response"
import { findUserTransactionIdRequest } from "@/api/transaction"
import moment from "moment-timezone"

export default {
  name: "TransactionDetailed",
  components: {
    TransactionInfo,
    TransactionDetailedTable
  },
  data() {
    return {
      isLoading: false,
      itemData: null,
    }
  },
  created() {
    this.fetchUserData()
  },
  methods: {
    statusColor(status) {
      if (status === "SUCCESS") {
        return "green-text"
      } else if (status === "PENDING" || status === "PROCESSING") {
        return "warn-text"
      } else {
        return "red-text"
      }
    },
    dateFormat(date) {
      return moment(date).format("DD-MM-YYYY")
    },
    async fetchUserData() {
      this.isLoading = true
      const params = { batch_id: this.$route.params.id }
      await findUserTransactionIdRequest(params)
        .then(fetchData)
        .then(item => {
          this.itemData = item
        })
        .catch(this.$notificationHandleResponseError)
        .finally(() => (this.isLoading = false))
    }
  }
}
</script>

<style scoped>
.info-line span:not(.label) {
  font-size: 16px;
}

.label {
  font-size: 14px;
  min-width: 180px;
  display: inline-block;
}

.main {
  padding: 16px;
  margin-bottom: 32px;
}

.titles {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 12px;
}
</style>
