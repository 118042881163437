<template>
  <v-container fluid grid-list-xl>
    <v-layout row wrap class="justify-center">
      <v-flex xs12 sm12 md12 lg12>
        <div class="content">
          <v-btn class="btn-back" tile flat @click="returnBack">
            <v-icon left>
              mdi-chevron-left
            </v-icon>
            Back
          </v-btn>
          <TransactionDetailed />
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import TransactionDetailed from "@/components/TransactionDetailed/TransactionDetailed"

export default {
  name: "TransactionDetailedPage",
  components: {
    TransactionDetailed
  },
  data() {
    return {
      prevRoute: null,
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  methods: {
    returnBack() {
      if (this.prevRoute && this.prevRoute.fullPath !== "/") {
        this.$router.push({ name: this.prevRoute.name })
      } else {
        this.$router.push("/")
      }
    }
  }
}
</script>

<style scoped>
.content {
  max-width: 1224px;
  margin: 0 auto;
  position: relative;
}

.btn-back {
  position: absolute;
  left: -120px;
  top: -8px;
}
</style>
