<template>
  <div class="mt-4 mb-5">
    <div class="head flex-block justify-space-between mb-2">
      <div class="label">
        Payment Status
      </div>
      <div class="label center-text">
        No. of payments
      </div>
      <div class="label right-text">
        Amount
      </div>
    </div>
    <div
      v-for="(item, index) in statuses"
      :key="index"
      class="flex-block justify-space-between mb-1 pb-1 item"
    >
      <div>{{ item.name }}</div>
      <div class="center-text">
        {{ item.count }}
      </div>
      <div class="right-text">
        ${{ item.amount }}
      </div> 
    </div>
    <div class="total mt-3">
      <div class="flex-block justify-end">
        <div class="left">
          <div class="mb-1">
            Payment Total
          </div>
          <div class="bb mb-2 pb-1">
            Fee
          </div>
          <div class="mb-1">
            Total
          </div>
        </div>
        <div class="sum right-text">
          <div class="mb-1">
            ${{ amount }}
          </div>
          <div class="bb mb-2 pb-1">
            ${{ fees }}
          </div>
          <div class="mb-1">
            ${{ (+amount + +fees).toFixed(2) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TransactionInfo",
  props: {
    statuses: {
      type: Array,
      default: () => []
    },
    amount: {
      type: [Number, String],
      default: ""
    },
    fees: {
      type: [Number, String],
      default: ""
    }
  }
}
</script>

<style scoped>
.left > div {
  font-size: 14px;
}

.item,
.bb {
  border-bottom: 1px solid grey;
}

.head {
  background: grey;
  padding: 4px 16px;
  margin: 0 -16px;
}

.head .label {
  font-size: 16px;
}

.flex-block:not(.justify-end) > div {
  flex: 0 0 33.3333333333%;
}

.sum.right-text > div {
  padding-left: 16px;
}

.sum {
  min-width: 150px;
  display: inline-block;
}

.center-text {
  text-align: center;
}

.right-text {
  text-align: right;
}
</style>
