<template>
  <div>
    <div class="flex-block justify-space-between align-center mb-3">
      <TableSearch :max-search="255" @search="searchCampaign" />
      <div class="select-wrap">
        <v-select
          v-model="selectedStatus"
          :items="statusItems"
          :menu-props="{ contentClass: 'select-custom-drop' }"
          item-text="name"
          item-value="value"
          color="#7A6FDB"
          return-object
          hide-details
          solo
          class="select-custom"
          @change="refreshPagination()"
        />
      </div>
    </div>
    <v-card class="main">
      <v-data-table
        :items="dataList"
        :pagination.sync="pagination"
        :loading="isLoading"
        :total-items="total"
        :rows-per-page-items="[10, 25, 50, 100]"
        class="elevation-1"
        :headers-length="6"
        :headers="headers"
      >
        <template slot="items" slot-scope="props">
          <td>
            <router-link :to="`/moderation/user_list/user/${props.item.user.id}`" class="link-white">
              <span class="break">{{ props.item.user.username }}</span>
            </router-link>
          </td>
          <td>
            <span
              :class="`${+props.item.amount > 0 ? 'green-text' : 'red-text'}`"
              class="bold"
            >
              ${{ props.item.amount }}
            </span>
          </td>
          <td>
            <span
              :class="`${+props.item.fees > 0 ? 'green-text' : 'red-text'}`"
              class="bold"
            >
              ${{ props.item.fees }}
            </span>
          </td>
          <td>
            <span
              :class="`${+props.item.amount + +props.item.fees > 0? 'green-text': 'red-text'}`"
              class="bold"
            >
              ${{ (+props.item.amount + +props.item.fees).toFixed(2) }}
            </span>
          </td>
          <td>
            <span :class="statusColor(props.item.status)" class="bold">
              {{ props.item.status }}</span>
          </td>
          <td>
            {{ dateFormat(props.item.date) }}
          </td>
        </template>
      </v-data-table>

      <div class="text-xs-center pt-2">
        <v-pagination
          v-model="pagination.page"
          :length="pages"
          @input="refreshPagination"
        />
      </div>
    </v-card>
  </div>
</template>

<script>
import SavesPagination from "@/mixins/SavesPaginationMixin"
import moment from "moment-timezone"
import TableSearch from "@/components/Elements/TableSearch"
import { fetchData } from "@/utils/response"
import { transactionListRequest } from "@/api/transaction"

export default {
  name: "AdvertisingActiveTable",
  components: { TableSearch },
  mixins: [SavesPagination],
  props: {
    transactionsId: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      headers: [
        { text: "User", sortable: false, value: "user.username" },
        { text: "Amount", sortable: false, filterable: false, value: "amount" },
        {
          text: "Commission",
          sortable: false,
          filterable: false,
          value: "fees"
        },
        { text: "Total", sortable: false, value: "total" },
        { text: "Status", sortable: false, filterable: false, value: "" },
        { text: "Date", sortable: false, filterable: false, value: "" }
      ],
      dataList: [],
      selectedStatus: { name: "All Statuses", value: "" },
      statusItems: [
        { name: "All Statuses", value: "" },
        { name: "Success", value: "SUCCESS" },
        { name: "Pending", value: "PENDING" },
        { name: "Failed", value: "FAILED" },
        { name: "Unclaimed", value: "UNCLAIMED" },
        { name: "Returned", value: "RETURNED" },
        { name: "Onhold", value: "ONHOLD" },
        { name: "Blocked", value: "BLOCKED" },
        { name: "Refunded", value: "REFUNDED" },
        { name: "Reversed", value: "REVERSED" }
      ],
      isLoading: false,
      pagination: {},
      total: 0,
      search: '',
    }
  },
  computed: {
    pages() {
      if (this.pagination.rowsPerPage == null || this.total == null) return 0

      return Math.ceil(this.total / this.pagination.rowsPerPage)
    }
  },
  watch: {
    /**
     * Watch pagination changes and load more data
     */
    pagination(pagination) {
      this.$paginationCacheSave(pagination.page, pagination.rowsPerPage)
      this.refresh()
    }
  },
  mounted() {
    const pagination = this.$paginationCacheGet()
    this.pagination.page = pagination.page
    this.pagination.rowsPerPage = pagination.per_page
  },
  methods: {
    statusColor(status) {
      if (status === "SUCCESS") {
        return "green-text"
      } else {
        return "red-text"
      }
    },
    searchCampaign(val) {
      this.search = val
      this.refreshPagination()
    },
    refreshPagination() {
      this.refresh()
      this.$paginationCacheSave(
          this.pagination.page,
          this.pagination.rowsPerPage
      )
    },
    async refresh() {
      this.isLoading = true
      const params = {
        page: this.pagination.page,
        per_page: this.pagination.rowsPerPage,
        search: this.search,
        status: this.selectedStatus.value,
        batch_id: this.transactionsId
      }
      this.dataList = await transactionListRequest(params)
          .then(response => {
            this.total = response.data.meta.total
            // this.pages = Math.ceil(this.total / this.limit)
            return response
          })
          .then(fetchData)
          .then(data =>
              data.map(item => {
                return item
              })
          )
          .catch(this.$notificationHandleResponseError)
          .finally(() => {
            this.isLoading = false
          })
    },
    dateFormat(date) {
      return moment(date).format("DD-MM-YYYY")
    }
  }
}
</script>

<style scoped>
>>>.v-datatable.v-table thead tr:first-of-type {
  display: table-row;
}
.main {
  padding: 16px;
}
</style>
